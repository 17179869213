import React, { useContext, useEffect, useState } from 'react';
import { csv } from 'd3';
import { FileValidated } from '@dropzone-ui/react';
import { useOktaAuth } from '@okta/okta-react';
import { Divider, Grid } from '@mui/material';
import {
  AlertMsgContext,
  AlertMsgType,
} from '../mir-styles/src/providers/AlertMsgProvider';
import {
  ExtraFileType,
  FileToOverwriteType,
  FileType,
  ReportGroupType,
  TestsContext,
} from '../providers/TestsProvider';
import { TestsType } from '../providers/TestsProvider';
import { RegionsContext, RegionsType } from '../providers/RegionsProvider';
import JSZip from 'jszip';
import UploadFiles from './components/UploadFiles';
import Results from './components/Results';
import Reports from './components/Reports';
import PreviewSection from './components/PreviewSection';
import OverwriteFileModal from './components/OverwriteFileModal';
import './MirClassifierPage.css';

const MirClassifierPage: React.FC = () => {
  //Move to auth provider
  const { oktaAuth } = useOktaAuth();

  //Contexts -------------------------------------------------------------------------------------
  const { updateAlertMsg } = useContext(AlertMsgContext) as AlertMsgType;
  const { regionsState } = useContext(RegionsContext) as RegionsType;
  const {
    resultListState,
    reportListState,
    updateResultList,
    updateResultListReports,
    getTestsFromBucket,
    getCsvFromBucket,
    putTxtFileInBucket,
    approveTests,
    getUploadUrl,
    getPdfFromBucket,
  } = useContext(TestsContext) as TestsType;

  //UseState Classifier---------------------------------------------------------------------------
  const [files, setFiles] = useState<FileValidated[]>([]);
  const [checked, setChecked] = React.useState<FileType[]>([]);
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);
  const [refreshInterval, setRefreshInterval] = React.useState<NodeJS.Timer | undefined>(
    undefined
  );
  const [filesToApprove, setFilesToApprove] = React.useState<any>();

  //UseState Overwrite Files----------------------------------------------------------------------
  const [filesnames, setFilesNames] = useState<{ filename: string }[]>([]);
  const [filesToOverwrite, setFilesToOverwrite] = useState<FileToOverwriteType[]>([]);
  const [filesToOverwriteObjects, setFilesToOverwriteObjects] = useState<FileValidated[]>(
    []
  );
  const [isUploadingOverwrite, setIsUploadingOverwrite] = React.useState<boolean>(false);
  const [overwriteFileName, setOverwriteFileName] = React.useState<string>('');
  const [showOverwriteModal, setShowOverwriteModal] = React.useState<boolean>(false);

  //UseState Report Generator---------------------------------------------------------------------
  const [checkedReporter, setCheckedReporter] = React.useState<ReportGroupType[]>([]);
  const [isDownloadingReporter, setIsDownloadingReporter] =
    React.useState<boolean>(false);

  //UseState Preview Section----------------------------------------------------------------------
  const [showPreview, setShowPreview] = React.useState<boolean>(false);
  const [filesPreview, setFilesPreview] = useState<any[]>([]);
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const [isDownloadingPreview, setIsDownloadingPreview] = React.useState<boolean>(false);
  const [previewFileName, setPreviewFileName] = useState<string>('');
  const [previewFileUiid, setPreviewFileUiid] = useState<string>('');

  //UseEffects -----------------------------------------------------------------------------------

  useEffect(() => {
    setFiles([]);
    setChecked([]);
    if (!!regionsState.region) {
      clearInterval(refreshInterval);
      const intervalFiles = setInterval(() => {
        refreshFiles(regionsState.region!.id);
      }, 5000);
      setRefreshInterval(intervalFiles);
      refreshFiles(regionsState.region!.id);
      return () => clearInterval(intervalFiles);
    }
  }, [regionsState.region]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (previewFileName) {
      getFileToPreview();
    }
  }, [previewFileName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filesToOverwrite.length > 0) {
      setOverwriteFileName(filesToOverwrite[0].filename);
      setShowOverwriteModal(true);
    }
  }, [filesToOverwrite]);

  // OVERWRITEL FILES-----------------------------------------------------------------------------
  /*
   * Takes the first file from the filesToOverwrite array and sets its overwrite property to true.
   * This allows that when its information is sent to the /getUploadUrl endpoint, we will get the necessary signedUrl
   * to put the file into the bucket (upload it).
   */
  const overwriteFile = () => {
    setIsUploadingOverwrite(true);

    let file: FileToOverwriteType = filesToOverwrite[0];
    file.overwrite = true;

    getUploadUrl(oktaAuth.getAccessToken() || '', regionsState.region!.id, [file]).then(
      (res: any) => {
        res.forEach((file: FileToOverwriteType) => {
          const fileObject = filesToOverwriteObjects.filter(
            (f) => f.file.name === file.filename + '.txt'
          )[0];
          //The file name is a read only property, so a file copy with a new name (filename + the uuid) is created.
          const fileObjectWithUuid = new File(
            [fileObject.file],
            file.uuid + file.filename + '.txt',
            { type: fileObject.file.type, lastModified: fileObject.file.lastModified }
          );

          putTxtFileInBucket(
            !!file.uuid ? fileObjectWithUuid : fileObject.file,
            file.signedURL!
          ).then((res: any) => {
            const newFiles = filesToOverwriteObjects.filter(
              (f) => f.file.name !== file.filename + '.txt'
            );
            setFilesToOverwriteObjects(newFiles);
            setIsUploadingOverwrite(false);
            cancelModal();
          });
        });
      }
    );
  };

  /*
   * Removes the current txt file selected in the overwrite modal from the
   * filesToOverwrite and the filesToOverwriteObjects arrays and closes the modal.
   */
  const cancelModal = () => {
    let newFilesOv = [...filesToOverwrite];
    let newFilesOvObjects = [...filesToOverwriteObjects];

    newFilesOv.shift();
    newFilesOvObjects.shift();

    setFilesToOverwrite(newFilesOv);
    setFilesToOverwriteObjects(newFilesOvObjects);
    setShowOverwriteModal(false);
  };

  // CLASSIFIER-----------------------------------------------------------------------------------
  /**
   * Updates the results(csvs) and reports(pdf) lists by calling the /getCsvListFromBucket method.
   */
  const refreshFiles = async (selectedRegion: string) => {
    const res = await getTestsFromBucket(oktaAuth.getAccessToken() || '', selectedRegion);

    if (res) {
      //Refresh csvs
      updateResultList(res);

      //Refresh pdfs
      if (res.Items && res.Items.length > 0) {
        let newReports: ReportGroupType[] = [];

        res.Items.forEach((result: FileType) => {
          if (result.reports && result.reports!.length > 0) {
            const obj: ReportGroupType = {
              test_id: result.test_id,
              createdAt: result.createdAt,
              reports: result.reports!,
              uuid: result.testUUID!,
            };
            newReports.push(obj);
          }
        });
        updateResultListReports(newReports);
      } else if (res.Items && res.Items.length === 0) {
        updateResultListReports([]);
      }
    } else {
      updateResultList(undefined);
      updateResultListReports(undefined);
    }
  };

  /**
   * Updates the txt list with the incomingFiles.
   * @param incomingFiles Txts dropped or selected in the dropzone.
   */
  const updateFiles = (incomingFiles: FileValidated[]) => {
    if (isUploading) {
      updateAlertMsg({
        severity: 'error',
        message: `Can't add files while uploading.`,
        open: true,
      });
      setFiles(files);
      setFilesNames(filesnames);
      return;
    }

    let newFiles: FileValidated[] = [];
    let newFilesNames: { filename: string }[] = [];

    incomingFiles.forEach((file) => {
      if (!file.valid) {
        updateAlertMsg({
          severity: 'error',
          message:
            'The filetype for file "' + file.file.name + '" is not a valid filetype.',
          open: true,
        });
      } else {
        newFiles.push(file);
        newFilesNames.push({ filename: file.file.name });
      }
    });
    setFiles(newFiles);
    setFilesNames(newFilesNames);
  };

  /**
   * Removes a txt form the txt list shown in the dropzone.
   */
  const onDelete = (id: string | number | undefined) => {
    setFiles(files.filter((x) => x.id !== id));
  };

  /**
   * Uploads the txt files form the dropzone.
   * First, the /getUploadUrls method is called, which returns signedURLs and information about
   * the existance of files with the same name in the s3 bucket.
   * Then, those which don't have repeated names are uploaded into the s3 txt bucket with the /putTxtFileInBucketand
   * method and the ones which have repeated names are stored in the filesToOverwrite array.
   */
  const uploadTxtFiles = () => {
    setIsUploading(true);

    let newFilesOv = [...filesToOverwrite];
    let newFilesOvObjects = [...filesToOverwriteObjects];

    let uploads: Promise<any>[] = [];

    getUploadUrl(
      oktaAuth.getAccessToken() || '',
      regionsState.region!.id,
      filesnames
    ).then((res: any) => {
      res.forEach((file: FileToOverwriteType) => {
        const fileObject = files.filter((f) => f.file.name === file.filename + '.txt')[0];

        //The file name is a read only property, so a file copy with a new name (filename + the uuid) is created.
        const fileObjectWithUuid = new File(
          [fileObject.file],
          file.uuid + file.filename + '.txt',
          { type: fileObject.file.type, lastModified: fileObject.file.lastModified }
        );

        if (file.signedURL && !file.exists) {
          uploads.push(
            putTxtFileInBucket(
              !!file.uuid ? fileObjectWithUuid : fileObject.file,
              file.signedURL
            )
          );
        } else if (file.exists === true) {
          newFilesOv.push(file);
          newFilesOvObjects.push(fileObject);
        }
      });

      setFilesToOverwrite(newFilesOv);
      setFilesToOverwriteObjects(newFilesOvObjects);
      setFiles([]);

      Promise.all(uploads).then((values) => {
        setTimeout(() => {
          setIsUploading(false);
          values.length > 0 &&
            updateAlertMsg({
              severity: 'success',
              message: `Uploaded ${values.length}  ${
                values.length > 1 ? 'files' : 'file'
              } successfully.`,
              open: true,
            });
        }, 1000);
      });
    });
  };

  /**
   * Takes the previewFileName stored in the state and calls the /getCsvFromBucket.
   * Then, the method returns the csv file that its handled with the d3 library, which
   * allows us to store the csv information in the state for later viewing.
   */
  const getFileToPreview = () => {
    setIsDownloadingPreview(true);

    const fileKey: string = previewFileName!;

    getCsvFromBucket(
      fileKey,
      oktaAuth.getAccessToken() || '',
      regionsState.region!.id
    ).then((res: any) => {
      csv(res).then((data) => {
        setTableColumns(data['columns']);
        setFilesPreview(data);
        setIsDownloadingPreview(false);
      });
    });
  };

  /**
   * Downloads all the extra files (csv, control, pvalue) from each checked csv group.
   * It fetches the files using the /getCsvFromBucket method and stores them in a promise.
   * Then, it creates and downloads a zip with all files using the JSZip library.
   */
  const downloadSeveralFiles = () => {
    setIsDownloading(true);

    let downloads: Promise<any>[] = [];
    let blobs: File[] = [];
    let allExtraFiles: ExtraFileType[] = [];

    checked.forEach((c) =>
      c.extra_files.forEach((extraFile) => allExtraFiles.push(extraFile))
    );

    allExtraFiles.forEach((ef) => {
      const filename = ef.filename.split('/').at(-1);

      downloads.push(
        getCsvFromBucket(
          ef.filename,
          oktaAuth.getAccessToken() || '',
          regionsState.region!.id
        ).then((res: any) => {
          fetch(res)
            .then((response) => response.blob())
            .then((blob) => {
              blobs.push(
                new File([blob], filename || 'file', {
                  type: res.contentType,
                })
              );
            })
            .catch(console.error);
        })
      );
    });

    Promise.all(downloads).then((values) => {
      const zip = new JSZip();
      const zipFilename = `ResultList__${regionsState.region?.name}__${new Date()
        .toJSON()
        .slice(0, -5)
        .replace('T', '_')}.zip`;

      setTimeout(() => {
        blobs.forEach((blob) => {
          zip.file(blob.name, blob);
        });
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          const url = window.URL.createObjectURL(
            new File([content], zipFilename || 'file')
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${zipFilename}`);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          if (!!link) link?.parentNode?.removeChild(link);
        });
        setIsDownloading(false);
        setChecked([]);
      }, 1000);
    });
  };

  /**
   * Marks as checked or unckecked a csv group for downloading.
   * @param file Csv group.
   */
  const handleToggle = (file: FileType) => () => {
    if (isDownloading) {
      updateAlertMsg({
        severity: 'error',
        message: `Can't add files while downloading.`,
        open: true,
      });
      setFiles(files);
      return;
    }

    let newChecked = [...checked];
    const currentIndex = newChecked.map((obj) => obj.test_id).indexOf(file.test_id);

    if (currentIndex === -1) {
      newChecked.push(file);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  /**
   * Approves or rejects a group of csv and updates the state to show an approved
   * or rejected pill in each group.
   * @param filename Test id which allows to approve or reject all the cvs within a test.
   * @param approve  True for approval or false for rejection.
   */
  const approveRejectFile = (filename: string, approve: boolean) => {
    setFilesToApprove((prevFilesToApprove: any) => ({
      ...prevFilesToApprove,
      [filename]: true,
    }));

    approveTests(
      regionsState.region!.id,
      filename,
      approve,
      oktaAuth.getAccessToken() || ''
    ).then((res: any) => {
      updateResultList(res.message);
      setFilesToApprove((prevFilesToApprove: any) => ({
        ...prevFilesToApprove,
        [filename]: false,
      }));
    });
  };

  // REPORTER-------------------------------------------------------------------------------------

  /**
   * Marks as checked or unckecked a pdf group for downloading.
   * @param file Pdf group.
   */
  const handleToggleReporter = (file: ReportGroupType) => {
    if (isDownloading) {
      updateAlertMsg({
        severity: 'error',
        message: `Can't add files while downloading.`,
        open: true,
      });
      setFiles(files);
      return;
    }

    let newChecked = [...checkedReporter];
    const currentIndex = newChecked.map((obj) => obj.test_id).indexOf(file.test_id);

    if (currentIndex === -1) {
      newChecked.push(file);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedReporter(newChecked);
  };

  /**
   * Downloads all the reports (pdf) from each checked pdf group.
   * It fetches the files using the /getPdfFromBucket method and stores them in a promise.
   * Then, it creates and downloads a zip with all files using the JSZip library.
   */
  const downloadSeveralFilesReporter = () => {
    setIsDownloadingReporter(true);

    let downloads: Promise<any>[] = [];
    let blobs: File[] = [];
    let allReports: ExtraFileType[] = [];

    checkedReporter.forEach((r) =>
      r.reports.forEach((report) => allReports.push(report))
    );

    allReports.forEach((report) => {
      const filename = report.filename.slice(36);

      downloads.push(
        getPdfFromBucket(
          report.filename ? report.filename : '',
          oktaAuth.getAccessToken() || '',
          regionsState.region!.id
        ).then((res: any) => {
          fetch(res)
            .then((response) => response.blob())
            .then((blob) => {
              blobs.push(
                new File([blob], filename || 'file', {
                  type: res.contentType,
                })
              );
            })
            .catch(console.error);
        })
      );
    });

    Promise.all(downloads).then((values) => {
      const zip = new JSZip();
      const zipFilename = `ResultList__${regionsState.region?.name}__${new Date()
        .toJSON()
        .slice(0, -5)
        .replace('T', '_')}.zip`;

      setTimeout(() => {
        blobs.forEach((blob) => {
          zip.file(blob.name, blob);
        });
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          const url = window.URL.createObjectURL(
            new File([content], zipFilename || 'file')
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${zipFilename}`);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          if (!!link) link?.parentNode?.removeChild(link);
        });
        setIsDownloadingReporter(false);
        setCheckedReporter([]);
      }, 1000);
    });
  };

  return (
    <Grid
      container
      sx={{
        height: showPreview ? 'calc(100vh - 80px)' : '100%',
        maxWidth: showPreview ? '100%' : '1218px ',
        margin: '10px auto 0px auto',
        msScrollSnapY: '',
      }}
    >
      <Grid
        item
        sm={showPreview ? 6 : 12}
        md={showPreview ? 8 : 12}
        sx={{
          backgroundColor: 'var(--MirNeutral01)',
          height: showPreview ? 'calc(100vh - 80px)' : '100%',
          overflow: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {!showPreview && (
          <>
            <UploadFiles
              files={
                files.length > 0
                  ? files
                  : filesToOverwriteObjects.length > 0
                  ? filesToOverwriteObjects
                  : []
              }
              isUploading={isUploading}
              updateFiles={updateFiles}
              uploadFiles={uploadTxtFiles}
              onDelete={onDelete}
            />
            <Divider />
          </>
        )}
        <Results
          resultList={resultListState}
          checked={checked}
          isDownloading={isDownloading}
          handleToggle={handleToggle}
          downloadSeveralFiles={downloadSeveralFiles}
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          approveRejectFile={approveRejectFile}
          filesToApprove={filesToApprove}
          previewFileName={previewFileName}
          setPreviewFileName={setPreviewFileName}
          setPreviewFileUiid={setPreviewFileUiid}
        />
        {/* {!showPreview && (
          <>
            <Divider />
            <Reports
              resultList={reportListState}
              checked={checkedReporter}
              isDownloading={isDownloadingReporter}
              handleToggle={handleToggleReporter}
              downloadSeveralFiles={downloadSeveralFilesReporter}
            />
          </>
        )} */}
      </Grid>

      {/* OVERWRITE FILES MODAL */}
      {showOverwriteModal && (
        <OverwriteFileModal
          fileName={overwriteFileName}
          cancel={cancelModal}
          overwriteFile={overwriteFile}
          isUploadingOverwrite={isUploadingOverwrite}
          setShowOverwriteModal={setShowOverwriteModal}
          showOverwriteModal={showOverwriteModal}
        />
      )}

      {/* PREVIEW SECTION */}
      {showPreview && (
        <PreviewSection
          filesPreview={filesPreview}
          tableColumns={tableColumns}
          setShowPreview={setShowPreview}
          isUploading={isDownloadingPreview}
          setFilesPreview={setFilesPreview}
          setTableColumns={setTableColumns}
          previewFileName={previewFileName}
          previewFileUiid={previewFileUiid}
        />
      )}
    </Grid>
  );
};

export default MirClassifierPage;
